import React from "react"

import ContentFeatureImage from "../content/contentFeatureImage"
import Content from "./content"

const ContentContainer = ({ content }) => {
  return (
    <div>
      <ContentFeatureImage image={content.image} />
      <div className="max-w-5xl z-10 relative bg-white pt-10 px-6 md:px-10 md:-mt-72 md:mx-auto">
        <Content content={content} />
      </div>
    </div>
  )
}

export default ContentContainer
