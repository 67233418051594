import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faPinterestP,
} from "@fortawesome/free-brands-svg-icons"

const ContentShare = ({ data: { location, title, twitter, image }, type }) => {
  return (
    <div className="mb-10">
      <div className="text-xl uppercase text-brand text-left mb-4">
        {`Like This ${type}?`}
      </div>
      <div className="flex flex-col items-center md:flex-row">
        <div className="w-full md:w-1/2 md:mr-2">
          <a
            className="w-full flex flex-row items-center justify-start text-white mb-3 hover:opacity-75"
            href={`https://www.facebook.com/sharer/sharer.php?u=${location}`}
            target="_blank"
            rel="nofollow noopener noreferrer"
            style={{ backgroundColor: "#3b5998" }}
          >
            <div
              className="flex self-stretch justify-center items-center py-2"
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                width: "45px",
              }}
            >
              <FontAwesomeIcon
                className="mx-4 p-1"
                size="2x"
                style={{
                  height: "2rem",
                  width: "2rem",
                }}
                icon={faFacebookF}
              />
            </div>
            <span className="px-6">Share on Facebook</span>
          </a>
          <a
            className="w-full flex flex-row items-center justify-start text-white mb-3 hover:opacity-75"
            href={`https://twitter.com/intent/tweet/?text=${title}&url=${location}&via=${twitter}`}
            target="_blank"
            rel="nofollow noopener noreferrer"
            style={{ backgroundColor: "#1da1f2" }}
          >
            <div
              className="flex self-stretch justify-center items-center py-2"
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                width: "45px",
              }}
            >
              <FontAwesomeIcon
                className="mx-4 p-1"
                size="2x"
                style={{
                  height: "2rem",
                  width: "2rem",
                }}
                icon={faTwitter}
              />
            </div>
            <span className="px-6">Share on Twitter</span>
          </a>
        </div>
        <div className="w-full md:w-1/2 md:ml-2">
          <a
            className="w-full flex flex-row items-center justify-start text-white mb-3 hover:opacity-75"
            href={`https://www.linkedin.com/shareArticle?mini-true&url=${location}&title=${title}&source=${title}`}
            target="_blank"
            rel="nofollow noopener noreferrer"
            style={{ backgroundColor: "#0077b5" }}
          >
            <div
              className="flex self-stretch justify-center items-center py-2"
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                width: "45px",
              }}
            >
              <FontAwesomeIcon
                className="mx-4 p-1"
                size="2x"
                style={{
                  height: "2rem",
                  width: "2rem",
                }}
                icon={faLinkedinIn}
              />
            </div>
            <span className="px-6">Share on LinkedIn</span>
          </a>
          <a
            className="w-full flex flex-row items-center justify-start text-white mb-3 hover:opacity-75"
            href={`https://www.pinterest.com/pin/create/button/?url=${location}&media=${image.file.url}`}
            target="_blank"
            rel="nofollow noopener noreferrer"
            style={{ backgroundColor: "#bd081c" }}
          >
            <div
              className="flex self-stretch justify-center items-center py-2"
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                width: "45px",
              }}
            >
              <FontAwesomeIcon
                className="mx-4 p-1"
                size="2x"
                style={{
                  height: "2rem",
                  width: "2rem",
                }}
                icon={faPinterestP}
              />
            </div>
            <span className="px-6">Share on Pinterest</span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default ContentShare
