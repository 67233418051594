import React from "react"

import ContentHeader from "../content/contentHeader"
import ContentBlocks from "../content/contentBlocks"
import ContentTags from "./contentTags"
import Share from "./contentShare"
import ContentAuthor from "./contentAuthor"
import ContentComments from "./contentComments"

const Content = ({
  content: {
    title,
    type,
    image,
    author,
    blocks,
    categories,
    tags,
    twitter,
    location,
    published,
  },
}) => {
  return (
    <div>
      <ContentHeader data={{ title, categories, author, published, blocks }} />
      <ContentBlocks blocks={blocks} />
      <ContentTags tags={tags} />
      <ContentAuthor data={author} />
      <Share
        data={{ location: location.href, title, twitter, image }}
        type={type}
      />
      <ContentComments />
    </div>
  )
}

export default Content
