import React, { Fragment } from "react"

import { renderAst } from "../ast/renderAst"
import ContentAst from "./contentAst"
import ContentReview from "./contentReview"

const ContentBlocks = ({ blocks }) => {
  return (
    <>
      {blocks !== null &&
        blocks.map((block) => {
          return (
            <Fragment key={block.id}>
              {block.__typename === "ContentfulBlock" && (
                <div className="prose prose-lg max-w-none">
                  {block.components.map((component) => {
                    if (component.__typename === "ContentfulContentComponent") {
                      return (
                        <>
                          {renderAst(
                            ContentAst,
                            component.text.childMarkdownRemark.htmlAst
                          )}
                        </>
                      )
                    }
                    if (component.__typename === "ContentfulReviewComponent") {
                      return <ContentReview review={component} />
                    }
                    return null
                  })}
                </div>
              )}
            </Fragment>
          )
        })}
    </>
  )
}

export default ContentBlocks
