import React from "react"
import Img from "gatsby-image"

const ContentFeatureImage = ({ image }) => {
  return (
    <div>
      <Img
        fluid={image.fluid}
        style={{ maxHeight: "560px" }}
        alt={image.description}
      />
    </div>
  )
}

export default ContentFeatureImage
