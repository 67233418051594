import React from "react"
import { Link } from "gatsby"

const ContentTags = ({ tags }) => {
  return (
    <div className="mb-10 flex flex-wrap -ml-1">
      {tags.map(({ title, slug, id }) => {
        return (
          <Link
            key={id}
            className="text-sm bg-gray-200 py-1 px-2 mb-2 mx-1 rounded-sm hover:text-white hover:bg-brand whitespace-no-wrap"
            to={`/tag/${slug}/`}
          >
            {title}
          </Link>
        )
      })}
    </div>
  )
}

export default ContentTags
