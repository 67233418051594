import React from "react"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"

import { renderAst } from "../ast/renderAst"
import ContentAst from "./contentAst"

const ContentReview = ({
  review: {
    callout,
    ctaLink,
    ctaText,
    featuredReview,
    image: { fluid },
    reasonsToAvoid,
    reasonsToBuy,
    summary,
    title,
    text,
  },
}) => {
  return (
    <div>
      <Img
        fluid={fluid}
        // style={{ maxHeight: "560px" }}
        // alt={image.description}
      />
      <div className="-mt-16 bg-white pb-12 lg:-mt-20 lg:pb-20">
        <div className="relative z-0">
          <div className="w-full mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative">
              <div className="mt-10 max-w-none mx-auto lg:mt-0 lg:max-w-none lg:mx-0">
                <div className="relative z-10 rounded-lg shadow-xl">
                  <div className="pointer-events-none absolute inset-0 rounded-lg border-2 border-brand-500" />
                  <div className="absolute inset-x-0 top-0 transform translate-y-px">
                    <div className="flex justify-center transform -translate-y-1/2">
                      <span className="inline-flex rounded-full bg-brand-500 px-4 py-1 text-sm leading-5 font-semibold tracking-wider uppercase text-white">
                        {callout}
                      </span>
                    </div>
                  </div>
                  <div className="bg-white rounded-t-lg px-6 pt-12 pb-10">
                    <div>
                      <h3 className="text-center text-3xl leading-9 font-semibold text-gray-900 sm:-mx-6">
                        {title}
                      </h3>
                      <div className="mt-4 flex items-center justify-center">
                        {renderAst(
                          {
                            p: ({ children }) => (
                              <p className="mt-2 mr-2 text-xl md:text-2xl font-medium">
                                {children}
                              </p>
                            ),
                          },
                          summary.childMarkdownRemark.htmlAst
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="border-t-2 border-gray-100 rounded-b-lg pt-10 pb-8 px-6 bg-gray-50 sm:px-10 sm:py-10">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <h4 className="text-2xl leading-9 font-semibold text-gray-900 text-center md:text-left mb-4">
                          Reasons to Buy
                        </h4>
                        <ul>
                          {reasonsToBuy.map((reason) => {
                            return (
                              <li className="flex items-start">
                                <div className="flex-shrink-0">
                                  <FontAwesomeIcon
                                    className="text-green-500 mr-2"
                                    size="1x"
                                    style={{
                                      height: "1rem",
                                      width: "1rem",
                                    }}
                                    icon={faCheck}
                                  />
                                </div>
                                <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                                  {reason}
                                </p>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                      <div>
                        <h4 className="text-2xl leading-9 font-semibold text-gray-900 text-center md:text-left mb-4">
                          Reasons to Avoid
                        </h4>
                        <ul>
                          {reasonsToAvoid.map((reason) => {
                            return (
                              <li className="flex items-start">
                                <div className="flex-shrink-0">
                                  <FontAwesomeIcon
                                    className="text-red-500 mr-2"
                                    size="1x"
                                    style={{
                                      height: "1rem",
                                      width: "1rem",
                                    }}
                                    icon={faTimes}
                                  />
                                </div>
                                <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                                  {reason}
                                </p>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                    <div className="mt-10">
                      <div className="rounded-lg shadow-md">
                        <a
                          href={ctaLink}
                          className="block w-full text-center rounded-lg border border-transparent bg-brand-500 px-6 py-4 text-xl leading-6 font-medium text-white hover:opacity-75 focus:outline-none focus:border-brand-700 focus:shadow-outline-indigo transition ease-in-out duration-150"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {ctaText}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>{renderAst(ContentAst, text.childMarkdownRemark.htmlAst)}</div>
    </div>
  )
}

export default ContentReview
