import React, { Fragment } from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faUser,
  faComments,
  faCalendarAlt,
  faClock,
} from "@fortawesome/free-solid-svg-icons"

const ContentHeader = ({
  data: { author, categories, title, published, blocks },
}) => {
  return (
    <>
      <div className="text-center mb-10 uppercase">
        {categories.map(({ id, title, slug }, index) => (
          <Fragment key={id}>
            <Link
              className={`text-xl text-brand hover:opacity-75 ${
                index > 0 && "ml-4"
              }`}
              to={`/category/${slug}/`}
            >
              {title}
            </Link>
          </Fragment>
        ))}
      </div>
      <h1 className="text-center font-bold uppercase text-4xl leading-tight mb-10 md:text-5xl">
        {title}
      </h1>
      <div className="flex flex-col justify-center items-center border-t border-b border-dotted py-4 mb-10 text-lg md:flex-row">
        <Link
          className="text-md mx-4 hover:text-brand mb-1 md:mb-0"
          to={`/author/${author.slug}/`}
        >
          <FontAwesomeIcon
            className="text-brand mr-2"
            size="1x"
            style={{
              height: "1rem",
              width: "1rem",
            }}
            icon={faUser}
          />
          {author.name}
        </Link>
        {published !== null && published !== undefined && (
          <span className="text-md mx-4 mb-1 md:mb-0">
            <FontAwesomeIcon
              className="text-brand mr-2"
              size="1x"
              style={{
                height: "1rem",
                width: "1rem",
              }}
              icon={faCalendarAlt}
            />
            {published}
          </span>
        )}
        {blocks[0].components[0].__typename ===
          "ContentfulContentComponent" && (
          <span className="text-md mx-4 mb-1 md:mb-0">
            <FontAwesomeIcon
              className="text-brand mr-2"
              size="1x"
              style={{
                height: "1rem",
                width: "1rem",
              }}
              icon={faClock}
            />
            {
              blocks[0].components[0].text.childMarkdownRemark.fields
                .readingTime.text
            }
          </span>
        )}
        <span className="text-md mx-4">
          <FontAwesomeIcon
            className="text-brand mr-2"
            size="1x"
            style={{
              height: "1rem",
              width: "1rem",
            }}
            icon={faComments}
          />
          No Comments
        </span>
      </div>
    </>
  )
}

export default ContentHeader
