import React from "react"

const ContentComments = () => {
  return (
    <div>
      <div className="flex justify-center border border-gray-700 rounded-sm px-10 py-4">
        <span className="text-xl">Comments - Coming Soon!</span>
      </div>
    </div>
  )
}

export default ContentComments
