import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

const ContentAuthor = ({ data }) => {
  const { name, image, shortDescription, slug } = data
  return (
    <div className="mb-10">
      <div className="flex flex-col justify-center items-center border-t border-b border-dotted py-10 md:flex-row">
        <Link to={`/author/${slug}/`} className="w-full mb-4 md:w-1/5 md:m-8">
          <Img
            className="mx-auto rounded-full w-32"
            fluid={image.fluid}
            alt={image.description}
          />
        </Link>
        <div className="flex flex-col items-center md:w-4/5 md:items-start md:text-left">
          <div className="flex flex-col">
            <small className="uppercase">Written By</small>
            <span className="inline-block text-brand text-2xl mb-4 hover:opacity-75">
              <Link to={`/author/${slug}/`}>{name}</Link>
            </span>
          </div>
          <p className="prose prose-lg max-w-none">
            {shortDescription}
          </p>
          <Link
            className="inline-block uppercase whitespace-no-wrap font-normal m-4 py-2 px-6 border border-gray-800 hover:bg-brand hover:border-brand hover:text-white lg:py-3 lg:px-6 rounded-sm md:ml-0"
            to={`/author/${slug}/`}
          >
            All Content
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ContentAuthor
